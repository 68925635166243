<template>
	<div v-if="isList" class="content-wrapper">
		<bo-page-title>
			<template slot="additionalCta">
				<b-button v-if="activeTab == 2" @click="addNew()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
					<b><i class="icon-add"></i></b>
					Tambah Pesan Massal
				</b-button>
			</template>
		</bo-page-title>
		<div class="content pt-0">
			<div class="card">
				<div class="card-header bg-white">
					<div class="row align-items-center">
						<div class="col-md-5">
							<h5 class="card-title font-weight-semibold">Daftar Pesan Massal</h5>
						</div>
					</div>
				</div>

				<div class="card-header">
					<div class="row">
						<div class="col-md-8 col-lg-9">
							<div class="row gx-1">
								<div class="col-md">
									<div class="row gx-1">
										<div class="col-md-auto">
											<div class="form-group mb-0">
												<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField"
													data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i
														class="icon-spinner11"></i></button>
											</div>
										</div>

										<div class="col-md-5">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Dokter -- " @input="doFill" v-model="filter.dokter"
													:options="mDokter" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
								<input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama Dokter" />
								<div class="form-control-feedback">
									<i class="icon-search4 text-indigo"></i>
								</div>

								<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
									@click="doResetData()">Reset</b-button>
							</div>
						</div>
					</div>
				</div>


				<ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
					<li class="nav-item"><a href="javascript:;" @click="changeTab(1)"
							:class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Pesan Massal Dokter
							Cuti/Libur</a></li>
					<li class="nav-item"><a href="javascript:;" @click="changeTab(2)"
							:class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Pesan Massal Dokter Pindah
							Jadwal</a></li>
				</ul>
				<div class="tab-content">
					<div :class="'tab-pane fade show active'">
						<div class="table-responsive">
							<table
								class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
								<thead>
									<tr>
										<th>No.</th>
										<th>Nama Dokter</th>
										<th>Jam Periksa Dokter</th>
										<th>Poli</th>
										<th>Tanggal Libur</th>
										<th>Jumlah Reservasi</th>
										<th>Status</th>
										<th>Aksi</th>
									</tr>
								</thead>
								<tbody v-if="(dataList||[]).length">
									<tr v-for="(v,k) in (dataList||[])" :key="k">
										<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
										<td>{{v.bu_full_name||"-"}}</td>
										<td>{{v.arbm_jam_start}} s/d {{v.arbm_jam_end}}</td>
										<td>{{v.mpo_name||"-"}}</td>
										<td>{{v.arbm_date | moment("DD MMMM YYYY")}}</td>
										<td>{{v.jumlah_reservasi||"-"}}</td>
										<td>
											<div v-if="v.arbm_is_sent == 'Y'" class="badge bg-success">
												Pesan Terkirim
											</div>
											<div v-else class="badge bg-warning">
												Kirim Pesan
											</div>
										</td>

										<td>
											<router-link class="list-icons-item" v-if="moduleRole('view')" v-b-tooltip.hover.right
												:to="{name: $route.name, params: {pageSlug: v[idKey]}}" data-toggle="tooltip"
												data-placement="top" title="View"><i class="icon-eye"></i></router-link>
										</td>

									</tr>
								</tbody>
								<tbody v-if="data.data.length == 0">
									<tr>
										<td colspan="99">
											<div class="text-center">
												<h4 align="center"><span
														v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
														tidak ditemukan</span>
													<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
											</div>
										</td>
									</tr>
								</tbody>
								<tbody v-if="!data.data">
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
							<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
						</b-card-footer>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div v-else>
		<FormAdd v-if="isPageSlugTXT == 'add'" :row.sync="row" v-bind="passToSub" />
		<Form v-else :row.sync="row" v-bind="passToSub" />
	</div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    const moment = require('moment')
    import Form from './Form.vue'
    import FormAdd  from './FormAdd.vue'
    
    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            Form,FormAdd
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    dataReg: this.dataReg,
                    dataPesan: this.dataPesan
                }
            },
        },
        data(){
            return {
                idKey:'arbm_id',
                datePickerConfig: {
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                mDokter : [],
                dataReg: [],
                dataPesan: [],
                activeTab: 1,

            }
        },
        mounted(){
            this.doConvertDate()      
            this.apiGet()
            
            if(this.filter.dokter){
                this.filter.dokter = +this.filter.dokter
            }

            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            addNew(){
                this.$router.push({name: this.$route.name, params: {pageSlug: 'add'}})
            },
            changeTab(tab){
                this.activeTab = tab
                this.filter.typeBlast = tab == 2 ? 'PJ' : 'CT'
                this.doFill()
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: null,
                    endDate: null,
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },

            doConvertDate(){
                if(this.dateRange.startDate && this.dateRange.endDate){
                    this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                    this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
                }
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()

                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>