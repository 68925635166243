<template>
  <div class="content">
		<b-card no-body>
			<validation-observer ref="VForm">
				<b-form @submit.prevent="doSubmit" class="card">
					<div class="card">
						<div class="card-header">
							<div class="row">
								<div class="col-md-12">
									<h6><b>Form Kirim Pesan Massal</b></h6>
								</div>
							</div>
						</div>

						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<table>
										<tr>
											<td class="font-weight-semibold">Tanggal</td>
											<td class="px-1">:</td>
											<td>
												<div class="input-group">
													<datepicker input-class="form-control transparent"
														@input="selectJamPeriksa()"
														placeholder="Pilih Tanggal" class="my-datepicker"
														calendar-class="my-datepicker_calendar" v-model="row.arbm_date">
													</datepicker>
													<div class="input-group-append calendar-group">
														<span class="input-group-text" id="basic-addon2"><i
																class="icon-calendar"></i></span>
													</div>
												</div>
												<VValidate name="Tanggal" v-model="row.arbm_date"
													:rules="toValidate(mrValidation.arbm_date)" />
											</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Nama Dokter</td>
											<td class="px-1">:</td>
											<td>
												<v-select placeholder="Pilih Dokter" v-model="row.arbm_dokter_id"
													:options="mDokter" label="text"
													@input="selectJamPeriksa()"
													:clearable="true" :reduce="v=>v.value">
												</v-select>

												<VValidate name="Nama Dokter" v-model="row.arbm_dokter_id"
													:rules="toValidate(mrValidation.arbm_dokter_id)" />
											</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Poli</td>
											<td class="px-1">:</td>
											<td>{{row.mpo_name || "-"}}</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Jam Periksa</td>
											<td class="px-1">:</td>
											<td>
												<v-select	@input="selectReg()" placeholder="Pilih Jam Periksa" v-model="row.arbm_arjd_id"
													:options="mJadwalDokter" label="text"
													:clearable="true" :reduce="v=>v.value">
												</v-select>
												<VValidate name="Jam Periksa" v-model="row.arbm_arjd_id"
													:rules="toValidate(mrValidation.arbm_arjd_id)" />
											</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Jumlah Reservasi</td>
											<td class="px-1">:</td>
											<td>{{row.jumlah_reservasi || "0"}}</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Alasan Pembatalan Jadwal
											<span class="text-danger">*</span>
											</td>
											<td class="px-1">:</td>
											<td style="width: 375px;">
												<b-textarea v-model="row.arbm_alasan"
													rows="3" @input="inputAlasan($event)" class="form-control" />
												<!--
												<b-form-input v-model="row.arbm_alasan"
													rows="3" @input="inputAlasan($event)" class="form-control" />
													-->
												<VValidate name="Alasan Pembatalan Jadwal" v-model="row.arbm_alasan"
												:rules="{required: 1, min:3, max: 256}" />
											</td>
										</tr>
										<tr>
											<td class="font-weight-semibold">Preview Pesan</td>
											<td class="px-1"></td>
											<td></td>
										</tr>
									</table>
								</div>
								<div class="col-md-7">
									<b-form-textarea id="preview_template" :value="`Kepata Yth ${nama_pasien||'Bambang Wijaya'}, \nKami Bagian Informasi RS Harapan Magelang ingin menginformasikan bahwa terdapat perubahan jadwal pemeriksaan ${getConfigDynamic(mDokter,row.arbm_dokter_id)||'-'} pada tanggal ${tanggal_periksa||'-'}.*${row.arbm_alasan||'-'}*. Terima Kasih.`" disabled rows="5" class="form-control">
									</b-form-textarea>
								</div>

								<div class="col-md-12 mt-2" v-if="(dataReg||[]).length">
									<table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
										<thead>
												<tr>
														<th>#.</th>
														<th>Antrean</th>
														<th>Nama</th>
														<th>No. RM</th>
														<th>Cara Bayar</th>
														<th>No. HP</th>   
														<th>
															<b-form-checkbox
																@change="checkData($event,true)"
																v-model="checkAll"
																:value="true"
																		:unchecked-value="false"
																id="check-all" 
																name="check-all-id"
															>
															</b-form-checkbox>
														</th>
												</tr>
												<tr v-for="(v,k) in (dataReg||[])" :key="k">
													<td>{{k+1}}</td>
													<td>{{v.ar_no_antrian||"-"}}</td>
													<td>{{v.nama_pasien||"-"}}</td>
													<td>{{v.ap_code||"-"}}</td>
													<td>{{v.mcp_name||"-"}}</td>
													<td>
														<div class="d-flex">
															<template v-if="!v.isEdit">
																{{v.ap_phone_number||"-"}}
															</template>
															<template v-else>
																<b-form-input v-model="v.ap_phone_number" type="text" class="form-control" placeholder="Nomor Telepon"/>
																
																<a href="javascript:;"
																v-b-tooltip.hover class="p-1 ml-1" @click="savePhone(k,v)" data-toggle="tooltip" data-placement="top" title="Simpan Nomor Handphone"><i style="font-size: 19px;" class="icon-checkmark-circle text-success"></i></a>
															</template>
														</div>
													</td>
													<td>
														<div class="d-flex">
															<b-form-checkbox
																v-if="v.ap_phone_number"
																@change="checkDataRow()"
																v-model="dataReg[k]['selected']"
																:name="'check-all'+k"
																:id="'check-all-id'+k"
																:value="true"
																:unchecked-value="false"
															>
															</b-form-checkbox>			

															<a href="javascript:;"
															v-if="!v.isEdit"
															v-b-tooltip.hover @click="changePhoneNum(k,true)" data-toggle="tooltip" data-placement="top" title="Edit Nomor Handphone"><i class="icon-pencil5" style="font-size: 18px;color: #333;"></i></a>
															
															<a href="javascript:;"
															v-else
															v-b-tooltip.hover @click="changePhoneNum(k,false)" data-toggle="tooltip" data-placement="top" title="Batal Ubah"><i class="icon-cross3 text-danger" style="font-size: 18px;color: #333;"></i></a>
														</div>
													</td>
												</tr>
										</thead>
									</table>
								</div>
							</div>
						</div>



						<div class="card-footer">
							<div class="text-right">
									<button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
									<button type="submit" class="btn btn-primary">Kirim <i class="icon-paperplane ml-2"></i></button>
							</div>
						</div>

					</div>
				</b-form>
			</validation-observer>
		</b-card>
	</div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default{
  props:{
    row:Object,
		dataPesan:Array,
		mrValidation:Object,
		Config:Object
  },
	components: {
		Datepicker
	},
	data(){
		return {
			checkAll: true,
			mDokter: [],
			mJadwalDokter: [],
			dataReg: [],
		}
	},
	computed:{
		nama_pasien(){
			let nama = ''
			if((this.dataReg||[]).length){
				nama = this.dataReg[0]['nama_pasien']
			}
			return nama
		},
		tanggal_periksa(){
			let date = ''
			if(this.row.arbm_date){
				date = moment(this.row.arbm_date, "YYYY-MM-DD").format('DD MMM YYYY')
			}
			return date
		},
	},
	methods: {	
		changePhoneNum(k,action){
			this.dataReg[k]['isEdit'] = action
			if(!action){
				this.$parent.apiGet()
			}
		},
		savePhone(k,row){
			this.$swal({
				icon: 'warning',
				title: 'Apakah Anda akan mengubah Nomor Telepon',
				showCancelButton: true,
				confirmButtonText: 'Ya',
				cancelButtonText: 'Tidak, kembali'
			}).then(result => {
				if (result.value) {
					let data = {
						type: "change-phone-number",
						phone_number: row.ap_phone_number,
						pasien_id: row.ar_ap_id
					}
					Gen.apiRest("/do/"+'WABlasting',{data: data}, "POST").then(res => {
						this.$swal({
							icon: 'success',
							title: 'Berhasil Mengubah Nomor Handphone',
							confirmButtonText: 'Ok',
							allowOutsideClick: false,
							allowEscapeKey: false
						}).then(resultAl => {
							if (resultAl.value) {
								let resp = res.data
								this.dataReg[k]['isEdit'] = false
							}
						})
					})	
				}
			})
		},
		checkDataRow(){
			// setTimeout(()=>{
				let totalAll = (this.dataReg||[]).length
				let selected = 0
				for(let i = 0; i < (this.dataReg||[]).length; i++){
					if(this.dataReg[i]['selected']){
						selected += 1
					}
				}
				if(selected == totalAll){
					this.checkAll = true
				}else{
					this.checkAll = false
				}
			// },250)


		},	
    checkData(e,isChecked=false){
		if(this.checkAll && isChecked){
			for(let i = 0; i < (this.dataReg||[]).length; i++){
				this.dataReg[i]['selected'] = true
			}
		}else if(!this.checkAll && isChecked){
			for(let i = 0; i < (this.dataReg||[]).length; i++){
				this.dataReg[i]['selected'] = false
			}
		}
	},
		getConfigDynamic(master, value, field='text') {
			let text = ''
			if (value) {
				let index = (master||[]).findIndex(x => x.value == value)
				if (index !== -1) {
					text = master[index][field]
				}
			}
			return text
		},
		
		toValidate(val){
			return {...val}
		},
		back() {
			this.$router.back()
		},
		inputAlasan: _.debounce(function (e) {
			let data = {
				type: "replace-text",
				text: e,
				preview_template: this.row.preview_template
			}
			Gen.apiRest("/do/"+'WABlasting',{data: data}, "POST").then(res => {
				let resp = res.data
				this.row.preview_template = resp.message
			})
		},500),
		selectJamPeriksa(){
			if(this.row.arbm_date && this.row.arbm_dokter_id){
				this.$parent.loadingOverlay = true
				let data = {
					type: 'get-jam-periksa',
					id_dokter: this.row.arbm_dokter_id,
					tanggal : this.row.arbm_date
				}
				Gen.apiRest(
					"/do/" + 'WABlasting', {
						data: data
					},
					"POST"
				).then(res => {
					let resp = res.data
					this.$parent.loadingOverlay = false 				
					this.mJadwalDokter = resp.mJadwalDokter
					this.row.mpo_name = resp.mpo_name
					this.row.mpo_id = resp.mpo_id
					this.row.arbm_mpo_id = resp.mpo_id

					this.row.arbm_arjd_id = null
					
				})
			}
		},
		selectReg(){
			if(this.row.arbm_date && this.row.arbm_arjd_id){
					this.$parent.loadingOverlay = true
					let data = {
						type: 'get-reg-data',
						jadwal_dokter_id: this.row.arbm_arjd_id,
						tanggal : this.row.arbm_date
					}
					Gen.apiRest(
						"/do/" + 'WABlasting', {
							data: data
						},
						"POST"
					).then(res => {
						let resp = res.data
						this.row.arbm_ar_id = resp.arbm_ar_id
						this.dataReg = resp.dataReg
						this.row.jumlah_reservasi = resp.jumlah_reservasi
						this.row.arbm_jam_start = this.getConfigDynamic(this.mJadwalDokter,this.row.arbm_arjd_id,'start_date')
						this.row.arbm_jam_end = this.getConfigDynamic(this.mJadwalDokter,this.row.arbm_arjd_id,'end_date')
						this.$parent.loadingOverlay = false 				
					})
			}	
		},
		doSubmit(){
			this.$refs['VForm'].validate().then(success => {
				if (!success) {
					return this.$swal({
						icon: 'error',
						title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
					})
				}
				if(success){
					this.$swal({
						icon: 'warning',
						title: 'Apakah Anda Yakin akan mengirimkan pesan massal?',
						showCancelButton: true,
						confirmButtonText: 'Ya',
						cancelButtonText: 'Tidak, kembali'
					}).then(result => {		
						if(result.value){
							let selected = 0
							let dataPost = []
							for(let i = 0; i < (this.dataReg||[]).length; i++){
								if(this.dataReg[i]['selected'] && this.dataReg[i]['ap_phone_number']){
									selected += 1
									this.dataReg[i]['keterangan'] = this.row.arbm_alasan
									this.dataReg[i]['preview_template'] = this.row.preview_template
									dataPost.push(this.dataReg[i])
								}
							}

							if(!selected){
								return this.$swal({
									icon: 'error',
									title: 'Tidak Ada Nomor Telepon Yang Terpilih', 
									text : 'Silakan Lengkapi Data Pasien Terlebih Dahulu'
								})
							}

							this.$parent.loadingOverlay = true

							let data = this.row
							data.dataPost = dataPost
							data.type = 'send-message-add'

							Gen.apiRest(
								"/do/" + 'WABlasting', {
									data: data
								},
								"POST"
							).then(res => {
								this.$parent.loadingOverlay = false
								let resp = res.data
								resp.statusType = 200
								

								this.$swal({
									title: resp.message,
									icon: resp.status,
									confirmButtonText: 'Ok',
									allowOutsideClick: false,
									allowEscapeKey: false
								}).then(result => {
									if (result.value) {
										this.$router.push({
											name: 'WABlasting',
											params: {pageSlug: resp.data.data.arbm_id}
										})
									}
								})
							}).catch(err => {
								this.$parent.loadingOverlay = false
								if (err) {
									err.statusType = err.status
									err.status = "error"
									err.title = err.response?.data?.title
									err.message = err.response?.data?.message
									err.messageError = err.message
								}
								this.$parent.doSetAlertForm(err)
							})
						}
					})
				}
			})
		},
		getMaster(){
			this.$parent.loadingOverlay = true
			let data = {
				type : 'get-master'
			}
			Gen.apiRest(
				"/do/" + 'WABlasting', {
					data: data
				},
				"POST"
			).then(res => {
 				this.$parent.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })		
				this.$parent.loadingOverlay = false

			})

		}
	},
	mounted() {
		this.getMaster()	
	},

}
</script>
